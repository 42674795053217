<style lang="scss">
#myprofile {
  position: relative;
  
  h3 {

    letter-spacing: 1px;
  }
  .back {
    padding-bottom:10px;
  }
  .btn-form-benutzer {
    padding: 10px 15px;
    height: auto;
    margin: 10px 0;
  }

  .headline {
    h1 {
      //font-size: 32px;
      font-size: 24px !important;
    }
  }
}
</style>

<template>
  <div id="myprofile">
    <a class="btn back" @click="goBack" href="#"><Icon :path="mdiChevronDoubleLeft" />Zurück</a>
    <div class="row headline">
      <h1 class="col-12">{{ person.nameFirst }} {{ person.nameLast }}</h1>
      <h2 class="col-12">SKILLS IM ÜBERBLICK</h2>
    </div>

    <Profile :skills="skills" :absprachen="person.absprachen" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Profile from '../../components/Profile.vue';
import { mdiChevronDoubleLeft, mdiAccountGroup   } from '@mdi/js';


export default defineComponent({
  components: {
    Profile,
  },
  setup() {
    const skills = ref([]);
    const store = useStore();
    const router = useRouter();
    const id = router.currentRoute.value.params.id;
    const person = ref({ nameFirst: '', nameLast: '', absprachen: '' });

    store.dispatch('fetchSkillsForUser', id).then((res) => {
      person.value = res.user;
      skills.value = res.skills
        .map((skill: any) => {
          skill.combined = skill.grades.dim1 + skill.grades.dim2;
          skill.percent1 = (skill.grades.dim1 / 3) * 100;
          skill.percent2 = (skill.grades.dim2 / 3) * 100;
          skill.percentall = Math.ceil((skill.combined / 6) * 100);
          return skill;
        })
        .sort((a: any, b: any) => {
          if (a.combined > b.combined) return -1;
          if (a.combined < b.combined) return 1;

          return 0;
        });
    });

    // store.dispatch('fetchSkillsForUser', id).then((res) => {

    //   skills.value = res.skills
    //     .map((skill: any) => {
    //       skill.combined = skill.grades.dim1 + skill.grades.dim2;
    //       skill.percent1 = (skill.grades.dim1 / 3) * 100;
    //       skill.percent2 = (skill.grades.dim2 / 3) * 100;
    //       skill.percentall = Math.ceil((skill.combined / 6) * 100);
    //       max.value = max.value + 6;
    //       achieved.value = achieved.value + skill.combined;
    //       return skill;
    //     })
    //     .sort((a: any, b: any) => {
    //       if (a.combined > b.combined) return -1;
    //       if (a.combined < b.combined) return 1;

    //       return 0;
    //     });
    // });

    const goBack = () => {
      router.go(-1);
    };

    return {
      person,
      skills,
      goBack,
      mdiChevronDoubleLeft,
    };
  },
});
</script>
